import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
	Flex,
	Box,
	useBoolean,
	Stack,
	Heading,
	HStack,
	Text,
	Button,
	Tooltip,
	// , useToast
} from "@chakra-ui/react";
import { Switch, Route, useParams, Redirect, useLocation } from "react-router-dom";
import MainContent from "../../../legacy/App/components/MainContent";
import CarouselTabs from "../../../core/Layout/tabs/CarouselTabs";
import PayPeriodSelectNew from "./PayPeriodSelectNew";
import LoadingSection from "../../../core/ProgressIndicators/components/LoadingSection";

import { portalUser } from "../../../App";
import Payroll from "../classes/Payroll";

import TabPanelContainer from "../../../core/Examples/common/TabPanelContainer";

import { log, dateIsValid, formatValue, convertArrayToMap } from "../../../helperFunctions";

import ResourceGroupsPage from "../../../legacy/Payroll/pages/ResourceGroupsPage";
import Paystubs from "../../../legacy/Payroll/Paystubs";

const basepath = "new/timecard";

export default function TimecardNew({ tabVal = 0 }) {
	const [viewerMaxHeight, setViewerMaxHeight] = useState(1200);
	const [[currentTab, direction], setCurrentTab] = useState([tabVal, 0]);
	const setTab = useCallback(
		(newTab, newDirection) => {
			if (!newDirection) newDirection = newTab - currentTab;
			setCurrentTab([newTab, newDirection]);
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
		[currentTab]
	);

	const [payroll, setPayroll] = useState(portalUser.payroll ?? null);

	//DATA IS LOADING FLAG AND CALLBACK FUNCTION
	const [isLoading, setIsLoading] = useBoolean(Boolean(portalUser.payroll));
	const setDataLoading = useCallback(
		async function (callback) {
			if (!isLoading) {
				setIsLoading.on();
			}
			callback();
		},
		[isLoading, setIsLoading]
	);

	const initPayroll = useCallback(
		async (payroll = portalUser.payroll) => {
			let msUserID = portalUser.user.msUserID ?? portalUser?.msUser?.id ?? null;

			if (Boolean(msUserID) && !Boolean(payroll)) {
				payroll = new Payroll(portalUser?.payroll);
				payroll = await payroll.initPayroll();
			}
			setPayroll(payroll);
			setIsLoading.off();
		},
		[setIsLoading]
	);

	useEffect(() => {
		setDataLoading(() => initPayroll(payroll));
		// eslint-disable-next-line
	}, []);

	//Update portalUser payroll cache
	useMemo(() => {
		if (Boolean(payroll) && !isLoading) {
			portalUser.payroll = payroll;
		}
	}, [payroll, isLoading]);

	const location = useLocation();

	const tabs = useMemo(() => {
		// let pagesMap = convertArrayToMap([], "type", true);
		let mytime = {
			name: "My Timecard",
			tab: "mytime",
			heading: "Payroll & Timecard",
			subheading: "My Timecard",
		};
		let crewtime = {
			name: "Crew Time",
			tab: "crewtime",
			heading: "Payroll & Timecard",
			subheading: "Crew Time Entry",
		};
		let approve = {
			name: "Approve Time",
			tab: "approval",
			heading: "Payroll & Timecard",
			subheading: "Timecard Approval",
		};
		let groups = {
			name: "Manage Groups",
			tab: "groups",
			heading: "Payroll & Timecard",
			subheading: "Manage Resource Groups",
		};
		let paystubs = {
			name: "Paystubs",
			tab: "paystubs",
			heading: "Payroll & Timecard",
			subheading: "Past Paystubs",
		};
		return [mytime, crewtime, approve, groups, paystubs];
	}, []);

	const selectedTab = useMemo(() => {
		// log("selectedTab", tabs[currentTab]);
		return tabs[currentTab];
	}, [currentTab, tabs]);

	return (
		<MainContent
			currentTab={currentTab}
			onTabChange={setTab}
			title={selectedTab?.subheading}
			// subtitle={selectedTab?.heading}
		>
			<Flex bg="blackAlpha.500" direction="column" flex={1} justify="flex-start" w="full" h="full">
				<CarouselTabs
					tabs={tabs}
					currentTab={currentTab}
					direction={direction}
					setTab={setTab}
					viewerMaxHeight={viewerMaxHeight}
					setViewerMaxHeight={setViewerMaxHeight}
					pageOrientation={"portrait"}
				>
					<TabPanelContainer
						heading={selectedTab?.heading}
						subheading={selectedTab?.subheading}
						description={location.pathname}
					>
						<Flex w="full" flex={1} px={8}>
							<Flex w="full" flex={1} direction="column" bg="green.400">
								<Switch>
									<Route
										exact
										path={`/${basepath}`}
										children={
											<Redirect
												to={`/${basepath}/${selectedTab.tab}/${formatValue(
													portalUser?.user?.prEndDate,
													0,
													"dateURL"
												)}`}
											/>
										}
									/>
									<Route
										exact
										path={`/${basepath}/:tab`}
										children={
											<TimecardRouteViewsNew
												tab={selectedTab.tab}
												isLoading={isLoading}
												payroll={payroll}
											/>
										}
									/>
									<Route
										path={`/${basepath}/:tab/:prEndDate`}
										children={
											<TimecardRouteViewsNew
												tab={selectedTab.tab}
												isLoading={isLoading}
												payroll={payroll}
											/>
										}
									/>
								</Switch>
							</Flex>
						</Flex>
					</TabPanelContainer>
				</CarouselTabs>
			</Flex>
		</MainContent>
	);
}

function TimecardRouteViewsNew({ tab = null, isLoading = false, payroll = null }) {
	let params = useParams();
	if (!dateIsValid(params?.prEndDate)) {
		params.prEndDate = null;
	}

	return (
		<Stack flex={1} w="full">
			{/* TODO: PAY PERIOD SELECT ADD DISABLED OPTIONS */}
			<HStack w="full" p={2} bg="green.200">
				<PayPeriodSelectNew basepath={basepath} payroll={payroll} isLoading={isLoading} />
			</HStack>
			<Heading>{tab}</Heading>

			{isLoading ? (
				<LoadingSection />
			) : Boolean(tab) && tab !== params?.tab ? (
				<Redirect push to={`/${basepath}/${tab}/${formatValue(params?.prEndDate, 0, "dateURL")}`} />
			) : ["mytime", "crewtime", "approval"].includes(params?.tab) && Boolean(params?.prEndDate) ? (
				<TimecardEntryNew payroll={payroll} />
			) : ["mytime", "crewtime", "approval"].includes(tab) && !Boolean(params?.prEndDate) ? (
				<Redirect
					push
					to={`/${basepath}/${params?.tab}/${formatValue(portalUser?.user?.prEndDate, 0, "dateURL")}`}
				/>
			) : ["groups"].includes(params?.tab) ? (
				<ResourceGroupsPage />
			) : ["paystubs"].includes(params?.tab) ? (
				<Paystubs />
			) : (
				<Box>Something went wrong - there is an issue with the Week Ending Date</Box>
			)}
		</Stack>
	);
}

function TimecardEntryNew({ payroll = null, tab = null }) {
	let params = useParams();
	if (!dateIsValid(params?.prEndDate)) {
		params.prEndDate = null;
	}
	const companyMap = convertArrayToMap(portalUser?.getCompanies(), "prco");

	const timecardHeaders = useMemo(
		() =>
			payroll?.timecardHeaders?.filter(
				(d) => formatValue(d.prEndDate, 0, "date") === formatValue(params?.prEndDate, 0, "date")
			) ?? [],
		[params?.prEndDate, payroll?.timecardHeaders]
	);

	useMemo(() => {
		if (Boolean(timecardHeaders)) {
			log("timecardHeaders", timecardHeaders);
		}
	}, [timecardHeaders]);

	return (
		<Stack w="full" id="timecardTable">
			{/* <LoadingSection /> */}
			{timecardHeaders?.map((header, h) => (
				<Stack key={h} w="full" bg="red.200" rounded="md">
					<HStack w="full" p={2} bg="green.200">
						<Heading w="full" size="md" color="gray.500" textTransform="uppercase">
							{companyMap[header?.prco]?.company}
						</Heading>
					</HStack>

					{/* <TimecardEntryTable
				key={this.props.employee.prco + "-" + this.props.employee.employee + "-table"}
				dataView={"table"}
				currentPayPeriod={this.props.payPeriods?.currentPeriod}
				prco={this.props.prco}
				employee={this.state.employee}
				timecardList={this.state.timecardList}
				timecardJobs={jobs}
				getAvailableJobPhases={this.getAvailableJobPhases}
				addHours={this.addHours}
				updateTimecardItem={this.updateTimecardItem}
				cloneTimecard={this.cloneTimecard}
				ready={this.state.ready}
				saveTimecard={this.saveTimecard}
				sortHours={this.sortHours}
			/> */}

					<HStack spacing={2} w="full" justify="flex-end">
						{/* {(this.state.timecardList?.payweekHours?.length < 1 ||
							(this.state.timecardList?.payweekHours?.length === 1 &&
								this.state.timecardList?.payweekHours[0].keyId === undefined) ||
							(this.state.timecardList?.payweekHours?.length === 1 &&
								this.state.timecardList?.payweekHours[0].earnCode === 7)) &&
							Boolean(this.props.payPeriods?.currentPeriod) && ( */}
						<Tooltip label="Clone time from last week" placement="bottom">
							<Button
								size="sm"
								bg="white"
								id="cloneHourBtn"
								variant="outline"
								colorScheme="teal"

								// onClick={this.cloneTimecard}
							>
								<HStack align="center">
									<Text className="fas fa-retweet" />
									<Text lineHeight={1} textTransform="uppercase">
										CLONE
									</Text>
								</HStack>
							</Button>
						</Tooltip>
						{/* )} */}
						<Tooltip label="Add a timecard entry row" placement="bottom">
							<Button
								size="sm"
								colorScheme="teal"
								// isLoading={!this.state.ready}
								loadingText="LOADING"
								id="addHourBtn"
								variant="solid"
								// colorScheme={this.props.prco === "250" ? "blue" : "teal"}
								// onClick={this.addHours}
							>
								<HStack align="center">
									<Text className="fas fa-plus" />
									<Text lineHeight={1} textTransform="uppercase">
										HOURS
									</Text>
								</HStack>
							</Button>
						</Tooltip>
					</HStack>
				</Stack>
			))}
		</Stack>
	);
}
